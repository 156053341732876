import React, { lazy } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useUserStateChange from '../../../hook/useUserStateChange';
import {
  showCompliancePage,
  showHelpPage,
  showJoinNowPage,
  showMyAccountPage,
  showPrivacyPolicyPage,
  showTermsOfUsePage
} from '../../../services/navigation/navigation.service';
import { getMiddleSectionHeight, openNewBrowserTab } from '../../../services/util-service/util.service';
import { ModalController } from '../../../controllers/modal-controller/modal.controller';
import { UsersController } from '../../../controllers/users-controller/users.controller';
import { links } from '../../../links';
import { SignInController } from '../../../controllers/sign-in-controller/sign-in.controller';
import NavigationLinks from '../../NavigationLinks/NavigationLinks';
import { DashboardController } from '../../../controllers/dashboard-controller/DashboardController';
import { env } from '../../../env';

import './SideMenu.scss';

const SideMenu = (props) => {
  const { browse_config = [], header_links = [] } = DashboardController.getWhitelabelConfig();
  const { t } = useTranslation();
  const [logged] = useUserStateChange();

  const getSection3Items = () => {
    return [{
      key: 'newsletter',
      label: t('SideMenu.newsletter'),
      onClick: showNewsLetterDialog,
    }, {
      key: 'twitter',
      label: t('SideMenu.twitter'),
      onClick: openNewBrowserTab.bind(this, links.twitter),
    }];
  };

  const getSection4Items = () => {
    const items =
      env.REACT_APP_DISPLAY_AFFILIATE_PROGRAM === 'on'
        ? [
          {
            key: 'webmasters',
            label: t('SideMenu.webmasters'),
            onClick: openNewBrowserTab.bind(this, links.webmasters),
          },
        ]
        : [];

    return items.concat({
      key: 'billingSupport',
      label: t('SideMenu.billingSupport'),
      onClick: showHelpPage,
      mailto: links.billingAndSupport,
    }, {
      key: 'help',
      label: t('SideMenu.help'),
      onClick: showHelpPage,
    });
  };

  const getSection5Items = () => {
    return [{
      key: '2257Compliance',
      label: t('SideMenu.2257Compliance'),
      onClick: showCompliancePage,
    }, {
      key: 'termsOfUse',
      label: t('SideMenu.termsOfUse'),
      onClick: showTermsOfUsePage,
    }, {
      key: 'privacyPolicy',
      label: t('SideMenu.privacyPolicy'),
      onClick: showPrivacyPolicyPage,
    }];
  };

  const getSection6Items = () => {
    return logged
      ? [{
        key: 'myAccount',
        label: t('SideMenu.myAccount'),
        onClick: showMyAccountPage,
      }, {
        key: 'signOut',
        label: t('SideMenu.signOut'),
        onClick: signOutUser,
      }]
      : [{
        key: 'joinNow',
        label: t('SideMenu.joinNow'),
        onClick: showJoinNowPage,
      }, {
        key: 'signIn',
        label: t('SideMenu.signIn'),
        onClick: showSignInDialog,
      }];
  };

  const showSignInDialog = () => {
    SignInController.showSignInDialog(true);
  };

  const onItemClick = (onClick, closeSignInDialog = true) => {
    onClick();
    props.onItemClick(closeSignInDialog);
  };

  const renderEntry = ({ key, label, onClick, mailto }) => {
    const propsData = {
      className: 'Entry',
      key,
    };
    let view;
    if (mailto) {
      Object.assign(propsData, {
        href: mailto,
        onClick: onItemClick.bind(this, props.onItemClick),
      });
      view = <a {...propsData}>{label}</a>;
    } else {
      propsData.onClick = onItemClick.bind(this, onClick, key !== 'signIn');
      view = <div {...propsData}>{label}</div>;
    }
    return view;
  };

  const renderSection = (items) => {
    const classes = ['Section'];

    return (
         <div className={classes.join(' ')}>
        {items.map(renderEntry)}
      </div>

    );
  };

  const signOutUser = () => {
    UsersController.signOutUser(t('SideMenu.loginOutMsg'));
  };

  const showNewsLetterDialog = () => {
    const SubscribeToNewsLetterDialog = lazy(
      () => import('../../SubscribeToNewsLetterDialog/SubscribeToNewsLetterDialog'),
    );
    const modal = <SubscribeToNewsLetterDialog />;
    ModalController.showModal(modal);
  };

  const classes = ['SideMenu'];
  const { className } = props;
  if (className) {
    classes.push(className);
  }
  const style = {
    height: getMiddleSectionHeight(true),
  };

  const header = document.querySelector('.MainDashboardHeader');
  if (header) {
    style.top = header.offsetTop + header.offsetHeight;
  }

  return (
    <div className={classes.join(' ')} style={style}>
      <div className="Section">
        <NavigationLinks links={browse_config} className='Entry' />
      </div>
      {browse_config.length !== 0 && <div className="Separator" />}
      <div className="Section">
        <NavigationLinks links={header_links} className='Entry' />
      </div>
      {header_links.length !== 0 && <div className="Separator" />}

      {renderSection(getSection3Items())}
      <div className="Separator" />
      {renderSection(getSection4Items())}
      <div className="Separator" />
      {renderSection(getSection5Items())}
      <div className="Separator" />
      {renderSection(getSection6Items(), true)}
    </div>
  );
};

SideMenu.propTypes = {
  className: PropTypes.string,
  onItemClick: PropTypes.func.isRequired
};

export default SideMenu;
