import { getJustAddedPageRoute } from '../../../services/navigation/navigation.service.routes';
import MoviesScenesGrid from '../../../components/MoviesScenesGrid/MoviesScenesGrid';
import { SecondaryNavigation } from '../../../constants/navigation';
import useMoviesScenesData from '../../../hook/useMoviesScenesData';

const StudioResultsTTBPrivCol = () => {
  const {
    videos: studio_1,
    loading,
    error,
  } = useMoviesScenesData({
    hookParams: { studios_id: 23803 },
  });

  const { videos: studio_2 } = useMoviesScenesData({
    hookParams: { studios_id: 23348 },
  });

  const allStudiosVideos = [...studio_1, ...studio_2];

  return (
    <MoviesScenesGrid
      title="Just Added"
      viewAllLink={{ text: 'View All', url: getJustAddedPageRoute(1, SecondaryNavigation.Newest) }}
      data={allStudiosVideos}
      loading={loading}
      error={error}
      hideNavigation
      hidePoster
    />
  );
};

StudioResultsTTBPrivCol.displayName = 'StudioResultsTTBPrivCol';

export default StudioResultsTTBPrivCol;
